import { array } from "prop-types"
import React, { useEffect, useState } from "react"
import { ListarLocalidad } from "utils/Querypanel"
import { insertLocalidad, getMapacolor, getLocalidadmapa } from "utils/Localidadmap"

import "./isvg.css"
import "./class.css"
import "./svg.css"
import "./cultura.css"
import ExampleDataTable from "components/ReactTable/Datatable.js/ExampleTable"
import ModalFirma from "views/Components/MODAL/Modalfirma"
const Viewssvg = () => {
  const [localidadmap, setselection] = useState({
    name: "",
    color: '#A12121',
  })
  const [mapa, setmapa] = useState([])
  const [lista, setLsita] = useState([])
  const [localidad, setLocalidad] = useState([])

  function handelChange(e) {
    setselection({
      ...localidadmap,
      [e.name]: e.value
    })

  }

  /*
    function agergaraALarray(dato,id,color){
       let array = lista       
      // let nuevo = mapa
     // console.log(array)
        var index = array.findIndex(obj => obj.path==dato);
        if (index == -1) { 
        array.push({path:dato,id:id, fill:color});
          
        } else {
        do {
          array.splice(index, 1);
         index = array.indexOf({path:dato,id:id, fill:color});
        } while (index != -1);
        }
        setLsita(array)
      //  console.log("mpap?",nuevo) 
      insertLocalidad(array,{path:dato,id:id, fill:color})
      cargarcolores()
      listadecolores()
        
      }
        
      function cargarcolores (){
        let colores = getMapacolor()
        colores.length>0? setLsita(colores):''
        colores.length>0? colores.map((e,i)=>{
          $("#"+e.path).attr("class","seleccion")               
          $("#"+e.path).attr("machfilal",e.fill,"class","seleccion")        
        }):''
      }
           $(document).on("click",".none",function(){
          let co = document.getElementById("color").value;
          let id = document.getElementById("name").value;
          if(this.classList.contains('none')){
            if(id.trim()=== "") {
            return  }
            else
          agergaraALarray(this.getAttribute('id'),id,co)   
                this.removeAttribute("class","")       
                this.setAttribute("class","seleccion")   
              }
           })
          $(document).on("click",".seleccion",function(){
           if(this.classList.contains('seleccion')){
            this.removeAttribute("machfilal")   
            agergaraALarray(this.getAttribute('id'),'','')
            this.removeAttribute("class","seleccion")   
            this.setAttribute("class","")                             
              } 
           })
    
     
           async function GetLocalidad(){
            let obtent = await ListarLocalidad()
            setLocalidad(obtent.data)
            let nuevo = obtent.data.map((e,i)=>{
              return{ id:e.id, nombre:e.nombre,color:''}
            })
            setmapa(obtent.data.map((e,i)=>{
              return{ id:e.id, nombre:e.nombre,color:''}
            }))
            //console.log("localidades",nuevo)
            sessionStorage.localidad = JSON.straingify(obtent.data)
            cargarcolores()   
          }
          async function listadecolores(){
            let nuevo = getLocalidadmapa()
            //  console.log("Function mapa",nuevo)
            let colores = getMapacolor()
            const valorDuplicadas = [];
            nuevo.length>0 && colores.length>0 ? colores.forEach(p => {
                if(valorDuplicadas.findIndex(pd => pd.id === p.id) === -1) {       
                 let index =nuevo.findIndex((e)=>parseInt(e.id)=== parseInt(p.id))
                  valorDuplicadas.push({id:p.id,nombre:nuevo[index]?nuevo[index].nombre:'',color:p.fill});
                }
                }):''     
            nuevo.length>0 && colores.length>0 ? nuevo.map((L)=>{
                if(valorDuplicadas.findIndex((e)=>parseInt(e.id)=== parseInt(L.id))!=-1){
                  L.color=valorDuplicadas[valorDuplicadas.findIndex((e)=>parseInt(e.id)=== parseInt(L.id))].color;
                  return L
                }else{
                  return L
                }
                }):''
             //         console.log("mutado",nuevo)  
    
               // console.log("duplicado",valorDuplicadas)
                nuevo.length>0 && colores.length>0?setmapa(nuevo) :''
                nuevo.length>0 && colores.length>0? sessionStorage.localidad = JSON.straingify(nuevo):''
          }*/

  useEffect(() => {
    const paths = document.querySelectorAll(" polygon.cuadro, rect.cuadro")
    paths.forEach(e => {
      e.addEventListener("click", function () {
        var t = document.createElementNS("http://www.w3.org/2000/svg", "text");
        var b = this.getBBox();
        console.log(b)
        console.log((b.x + b.width / 2) + " " + (b.y + b.height / 2))
        t.setAttribute("transform", "translate(" + (b.x + b.height / 8) + " " + (b.y + b.height / 2) + ")");
        t.textContent = "NOMBRE DE LOCALIDAD";
        t.setAttribute("machfilal", "white");
        t.setAttribute("font-size", (b.height / 10));
        this.parentNode.insertBefore(t, b.nextSibling);
        //  b.parentNode.insertBefore(t, b.nextSibling);

        //this.classList.add("class")   
        //this.setAttribute("machfilal","#DC2A27")
        //console.log( this)
      })
    })
    const textPaths = document.querySelectorAll("polygon.texto")

    textPaths.forEach(e => {
      e.addEventListener("click", function () {
        var t = document.createElementNS("http://www.w3.org/2000/svg", "text");
        var b = this.getBBox();
        console.log(b)
        //console.log((b.x + b.width/2) + " " + (b.y + b.height/2))
        t.setAttribute("transform", "translate(" + (b.x + b.height / 8) + " " + (b.y + b.height) + ")");
        t.textContent = "Sillas";
        t.setAttribute("machfilal", "white");
        t.setAttribute("font-size", (b.height));
        this.parentNode.insertBefore(t, b.nextSibling);
      })
    })


    //agregar id y class
    /*
 var t = document.createElementNS("http://www.w3.org/2000/svg", "text");
  var b = p.getBBox();
  t.setAttribute("transform", "translate(" + (b.x + b.width/2) + " " + (b.y + b.height/2) + ")");
  t.textContent = "a";
  t.setAttribute("machfilal", "red");
  t.setAttribute("font-size", "14");
  p.parentNode.insertBefore(t, p.nextSibling);*/

    /*
    const path = document.querySelectorAll("path")
      $(path).each(function(index){ 
        this.setAttribute("id", "mapas"+index)
        this.setAttribute("class", "mapas")
         // console.log(this)
        //alert($(this).text())
      });*/


  }, [])


  return (
    <>
      <ModalFirma />
      <div className="d-flex flex-wrap">
        <div className="col-12 col-md-6">
          <form className="container">
            <div className="row">
              <div className="col-8">
                <label className="form-label">Selecione localidad elija el color de la Localidad </label>
                <select className="form-control" value={localidadmap.name} name="name" id="name" onChange={(e) => handelChange(e.target)}>
                  <option value="">

                  </option>


                  {localidad.length > 0 ?
                    localidad.map((e, i) => {
                      return (
                        <option key={i} value={e.id} >{e.nombre}</option>
                      )
                    }) : ''
                  }
                </select>
              </div>
              <div className="col-sm">
                <label className="form-label" >.</label>
                <input
                  className="form-control form-control-color"
                  value={localidadmap.color} name="color" id="color"
                  type="color"
                  onChange={(e) => handelChange(e.target)}
                />
              </div>

            </div>


          </form>

        </div>
        <div className=" d-flex flex-column text-center justify-content-center col-12 col-md-6">
          <div className="d-flex flex-wrap justify-content-center  pb-5">
            {mapa.length > 0 ?
              mapa.map((elm, i) => {
                return (
                  <div className="d-flex flex-row px-3 precios align-items-center" key={i}  >
                    <div className="mx-1  rounded-4" style={{ height: 20, width: 20, backgroundColor: elm.color }}></div>
                    <span>{elm.nombre}</span>
                  </div>
                )
              }) : ''
            }
          </div>
          <div className=" border d-flex text-center bg-azul justify-content-center"  style={{ width: '100%' }}>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" viewBox="0 0 1080 1080" >
                <style>
                  {
                    ".st0{fill:#2992aa}.st3{fill:none;stroke:#8c8c8c;stroke-width:.3242;stroke-miterlimit:10}.st4{fill:#97c7d3}.st5{fill:#ffcf48}.st6{fill:#469baf}.st7{fill:#79e1f2}.st8{fill:#33aa6b}.st9{fill:#e6007e}.st10{fill:#ed5626}.st11{fill:#c96420}.st12{fill:#ed8526}.st13{fill:#75c7e0}.st14{fill:#298ea5}.st15{fill:#42abcc}.st18{fill:none}.st19{fill:#e8e8e8}.st20{fill:#fff}.st21{fill:#dee0ff}.st22{fill:#4242ad}.st23{fill:#161668}.st24{fill:#30308c}.st25{fill:#3737a0}.st26{fill:#c4c7f5}.st27{fill:#ffae04}.st31{font-family:&apos;Poppins-Black&apos;}.st32{font-size:36px}"
                  }
                </style>
                <path
                  className="st0"
                  d="M519.5 768.7s-148.2 55.9-254.1-23.5c0 0-63.5-43.2-32.8-123.7l11.6 6.4"
                />
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1={201.3857}
                  y1={472.1942}
                  x2={874.3439}
                  y2={472.1942}
                >
                  <stop offset={0.8241} stopColor="#ffed00" />
                  <stop offset={0.9598} stopColor="#ffa700" />
                </linearGradient>
                <path className='opciones' id='mapas4'
                  d="M201.4 550.2l72.8 39.5 177.4-103.6s125.1-83.9 236.2-51.8c0 0 108.7 19.8 112.9 96 0 0 5.7 40.7-14.8 69.5 0 0 12.4-8.8 28.4-16.4 0 0 63.5-31.5 59.8-105.9 0 0 2.8-84.2-128.5-120.5 0 0-123.3-39.1-235.8 18.8L201.4 550.2z"
                  fill="url(#SVGID_1_)"
                />
                <path
                  d="M295.1 595.5L494 480.7c77-44.5 171.9-44.5 249 0 55.7 32.2 55.7 112.7 0 144.8L545.8 739.3c-77.6 44.8-173.1 44.8-250.7 0-55.3-31.9-55.3-111.8 0-143.8z"
                  fill="#ef677e"
                />
                <path
                  className="st4"
                  d="M948.9 500.7V488c-.1 4.3-.5 8.6-1 12.8l1-.1zM874.8 611.5l-266 153.6v44.6l266.5-154.4c45.1-26.1 69.7-70 73.6-115.4v-39.1l-1 .2c-5.2 43.6-29.5 85.3-73.1 110.5z"
                />
                <path
                  className="st5"
                  d="M799.1 560v-.2.2zM799.7 557.4v-.1.1zM800.2 554.7z"
                />
                <path
                  className="st6"
                  d="M865.1 439c1 2.2 1.8 4.4 2.6 6.7-.8-2.3-1.7-4.5-2.6-6.7zM860.3 429.3c1.2 2.1 2.3 4.3 3.3 6.4-1-2.2-2.1-4.3-3.3-6.4zM869.7 451.7c-.3-.9-.5-1.8-.8-2.6.2.8.5 1.7.8 2.6zM854.5 420c1.4 2 2.7 4.1 4 6.1-1.3-2.1-2.6-4.1-4-6.1zM937.7 423.4c7.1 17.2 10.8 35.5 11.2 53.9.5-18.2-3.3-36.6-11.2-53.9zM871.7 459.3c-.6-2.6-1.2-5.1-2-7.7.7 2.6 1.4 5.2 2 7.7zM873.4 469c-.4-3.2-1-6.5-1.7-9.7.7 3.2 1.2 6.5 1.7 9.7zM874.3 480.5c0 1.2.1 2.4.1 3.5 0-1.2-.1-2.3-.1-3.5zM873.5 469.9c0-.3-.1-.6-.1-.8 0 .2 0 .5.1.8zM873.5 469.9c.4 3.5.7 7.1.8 10.6-.1-3.6-.4-7.1-.8-10.6z"
                />
                <path
                  className="st6"
                  d="M483.5 391.6c103.5-59.8 231.1-59.8 334.6 0 24.6 14.2 41.1 35.4 49.6 59 .6.4 1.3.7 1.9 1.1-.3-.9-.5-1.8-.8-2.6-.4-1.1-.8-2.3-1.2-3.4-.8-2.2-1.7-4.5-2.6-6.7l-1.5-3.3c-1-2.2-2.1-4.3-3.3-6.4-.6-1.1-1.2-2.1-1.8-3.1-1.2-2.1-2.6-4.1-4-6.1-9-13.1-21.2-24.5-36.4-33.3-3.2-1.9-6.5-3.7-9.8-5.4-98.4-52.5-216.6-52.5-315.1 0-3.3 1.8-6.5 3.6-9.8 5.4L201.4 550.2l4.3 2.4 277.8-161z"
                />
                <path
                  className="st6"
                  d="M873.4 469c-.4-3.2-1-6.5-1.7-9.7-.6-2.6-1.2-5.1-2-7.7-.6-.4-1.3-.7-1.9-1.1 2.1 5.9 3.7 11.9 4.8 18 .2.2.5.4.8.5zM872.5 468.5c1.1 5.9 1.7 11.9 1.8 17.9V484c0-1.2 0-2.4-.1-3.5-.1-3.5-.4-7.1-.8-10.6 0-.3-.1-.6-.1-.8-.2-.2-.5-.4-.8-.6z"
                />
                <path
                  className="st7"
                  d="M281 585.4l211.5-122.1c81.9-47.3 182.8-47.3 264.7 0 29.9 17.3 44.7 47.6 44.4 77.8.7-30.6-14.1-61.6-44.4-79.1-81.9-47.3-182.8-47.3-264.7 0L281 584.1c-2.8 1.6-5.4 3.3-7.9 5.1l1.1.6c2.1-1.6 4.4-3 6.8-4.4z"
                />
                <path
                  className="st0"
                  d="M280.2 728.5s53.1 41.3 132.8 43.6c48.7-1.2 97.2-14.3 140.7-39.4l212-123c17-12.8 28.4-30.1 33.3-48.8.1-.3.1-.5.2-.8v-.2c.2-.8.4-1.6.5-2.4v-.1c.2-.8.3-1.7.5-2.5.5-2.7.8-5.4 1.1-8.1-2.2-27.4-16.9-53.9-44.1-69.6-81.9-47.3-182.8-47.3-264.7 0L286 596.2l3.8 2c-.1 0-74.2 56.1-9.6 130.3zm42.1-122.9l192-110c62.8-36.3 140.3-36.3 203.1 0 45.5 26.3 45.5 91.9 0 118.1L526.8 723c-63.3 36.5-141.2 36.5-204.5 0-45.1-26.1-45.1-91.3 0-117.4z"
                />
                <path
                  className="st7"
                  d="M801.6 541.2v.9c-.1-.3 0-.6 0-.9zM774 603.5c-.4.3-.7.7-1.1 1 .4-.4.7-.7 1.1-1zM776.1 601.4l-1 1 1-1zM798.5 562.4c0 .1 0 .2-.1.3.1-.2.1-.2.1-.3zM771.8 605.4c-.4.3-.8.7-1.2 1 .5-.3.9-.6 1.2-1zM801.4 555.2c.2-3.5.3-7 .1-10.5v.1c.1 3.4.1 6.9-.1 10.4zM797.8 564.9c0 .1-.1.2-.1.3 0-.1.1-.2.1-.3zM797 567.5c0 .1-.1.2-.1.3 0-.1 0-.2.1-.3zM778.1 599.4c-.3.3-.6.7-1 1 .3-.3.6-.7 1-1zM796.1 570c0 .1-.1.2-.1.2s.1-.1.1-.2zM783.5 592.9c-.2.3-.5.6-.7.9.2-.2.5-.5.7-.9zM786.6 588.6c-.2.2-.3.5-.5.7.2-.2.3-.5.5-.7zM787.9 586.7c-.1.1-.1.2-.2.3.1-.2.1-.2.2-.3zM795.1 572.6s0 .1-.1.1l.1-.1zM785.1 590.8c-.2.3-.4.6-.6.8.2-.3.4-.6.6-.8zM801.3 556.5v0zM780 597.3c-.3.3-.6.7-.9 1 .3-.4.6-.7.9-1zM781.8 595.1c-.3.3-.5.7-.8 1 .2-.3.5-.7.8-1z"
                />
                <path
                  className="st7"
                  d="M765.6 609.6l4-2.3c.3-.3.6-.5 1-.8.4-.3.8-.7 1.2-1 .4-.3.7-.6 1.1-.9.4-.3.7-.7 1.1-1 .4-.3.7-.7 1-1l1-1c.3-.3.7-.7 1-1.1.3-.3.6-.7 1-1 .3-.4.7-.7 1-1.1.3-.3.6-.7.9-1 .3-.4.7-.8 1-1.2.3-.3.5-.6.8-1 .3-.4.7-.8 1-1.2.2-.3.5-.6.7-.9.3-.4.7-.9 1-1.3.2-.3.4-.6.6-.8.3-.5.7-1 1-1.5.2-.2.3-.5.5-.7.4-.6.7-1.1 1.1-1.7.1-.1.1-.2.2-.3 2.8-4.5 5.2-9.1 7.2-14 0 0 0-.1.1-.1.3-.8.6-1.5.9-2.3 0-.1.1-.2.1-.2.3-.8.5-1.5.8-2.3 0-.1.1-.2.1-.3.3-.8.5-1.5.7-2.3 0-.1.1-.2.1-.3.2-.8.4-1.5.7-2.3 0-.1 0-.2.1-.3.1-.5.3-1 .4-1.5-5 18.6-16.4 35.9-33.4 48.7z"
                />
                <path
                  className="st0"
                  d="M787.2 597.1l.8-.5c.1-.1.2-.1.3-.2 7.5-12.1 11.8-25.8 13-39.7-1.2 14.2-6 28.1-14.1 40.4zM801.5 544.8c0-.1 0-.1 0 0v.4c-.1-.1-.1-.3 0-.4zM800.2 554.7c-.1.8-.3 1.7-.5 2.5v.1c-.2.8-.3 1.6-.5 2.4v.2c0 .1-.1.3-.1.4 1.1-4.4 1.9-8.9 2.3-13.5v-.3c-.4 2.8-.7 5.5-1.2 8.2zM801.4 555.2c0 .4 0 .9-.1 1.3 0-.5.1-.9.1-1.3z"
                />
                <path
                  className="st0"
                  d="M801.3 546.6c-.2 2.7-.6 5.4-1.1 8.1.5-2.7.8-5.4 1.1-8.1zM799.2 559.8c.2-.8.4-1.6.5-2.4-.2.8-.4 1.6-.5 2.4zM799.7 557.3c.2-.8.3-1.7.5-2.5-.1.8-.3 1.6-.5 2.5zM798.9 560.8c0-.1.1-.3.1-.4 0-.1.1-.3.1-.4-.1.3-.1.6-.2.8z"
                />
                <path
                  className="st0"
                  d="M801.3 547.1v-.2c-.4 4.5-1.2 9.1-2.3 13.5-.2.6-.3 1.3-.5 1.9 0 .1 0 .2-.1.3-.2.8-.4 1.5-.7 2.3 0 .1-.1.2-.1.3-.2.8-.5 1.5-.7 2.3 0 .1-.1.2-.1.3-.3.8-.5 1.5-.8 2.3 0 .1-.1.2-.1.2-.3.8-.6 1.5-.9 2.3 0 0 0 .1-.1.1-2 4.8-4.3 9.5-7.2 14-.1.1-.1.2-.2.3-.4.6-.7 1.1-1.1 1.7-.2.2-.3.5-.5.7-.3.5-.7 1-1 1.5-.2.3-.4.6-.6.8-.3.4-.7.9-1 1.3-.2.3-.5.6-.7.9-.3.4-.7.8-1 1.2-.3.3-.5.7-.8 1-.3.4-.7.8-1 1.2-.3.3-.6.7-.9 1-.3.4-.7.7-1 1.1-.3.3-.6.7-1 1-.3.4-.7.7-1 1.1l-1 1-1 1c-.4.3-.7.7-1.1 1-.3.3-.7.6-1.1.9-.4.3-.8.7-1.2 1-.3.3-.6.5-1 .8L787 597c8.1-12.3 12.8-26.2 14.1-40.3v-.3c0-.4.1-.9.1-1.3.2-3.5.2-7 .1-10.4v.4c.1.7.1 1.4 0 2z"
                />
                <path
                  className="st0"
                  d="M781 596.1c-.3.4-.7.8-1 1.2.3-.4.6-.8 1-1.2zM782.8 593.9c-.3.4-.7.8-1 1.2.3-.4.6-.8 1-1.2zM772.9 604.5c-.3.3-.7.6-1.1.9.4-.3.7-.6 1.1-.9zM775 602.5l-1 1 1-1zM786.1 589.3c-.3.5-.7 1-1 1.5.4-.5.7-1 1-1.5zM784.5 591.6c-.3.4-.7.9-1 1.3.3-.4.7-.8 1-1.3zM798.5 562.4c.2-.6.3-1.3.5-1.9 0 .1-.1.3-.1.4-.1.4-.3 1-.4 1.5zM779.1 598.3c-.3.4-.7.7-1 1.1.3-.4.6-.8 1-1.1zM777.1 600.4c-.3.4-.7.7-1 1.1.3-.4.7-.8 1-1.1zM769.7 607.2c.3-.3.6-.5 1-.8-.4.3-.7.6-1 .8zM787.7 586.9c-.4.6-.7 1.1-1.1 1.7.4-.5.7-1.1 1.1-1.7zM797.7 565.2c-.2.8-.5 1.5-.7 2.3.2-.8.5-1.5.7-2.3zM798.4 562.6c-.2.8-.4 1.5-.7 2.3.3-.7.5-1.5.7-2.3zM795 572.7c-2 4.8-4.3 9.5-7.2 14 2.9-4.5 5.3-9.2 7.2-14zM796.9 567.8c-.3.8-.5 1.5-.8 2.3.3-.8.5-1.6.8-2.3zM796 570.3c-.3.8-.6 1.5-.9 2.3.3-.8.6-1.6.9-2.3zM801.5 544.7c0-.9.1-1.7.1-2.6 0 .7-.1 1.5-.1 2.2-.1.1-.1.3 0 .4zM801.6 541.1z"
                />
                <path
                  className="st0"
                  d="M757.1 463.3c-81.9-47.3-182.8-47.3-264.7 0L281 585.4c-2.4 1.4-4.6 2.8-6.8 4.4l11.8 6.4L492.5 477c81.9-47.3 182.8-47.3 264.7 0 27.2 15.7 41.9 42.2 44.1 69.6.1-.8.1-1.6.2-2.4v.1c0-.7.1-1.5.1-2.2v-.9-.1c.2-30.2-14.6-60.5-44.5-77.8z"
                />
                <path
                  className="st0"
                  d="M801.3 546.6v.3c0-.6.1-1.2.1-1.7v-.5-.4c0 .8-.1 1.5-.1 2.3z"
                />
                <path
                  className="st0"
                  d="M801.4 544.3s0-.1 0 0c0 .7-.1 1.5-.2 2.3.1-.8.2-1.5.2-2.3zM801.4 545.2c0 .6-.1 1.2-.1 1.7v.2c.1-.6.1-1.3.1-1.9z"
                />
                <path className="st8" d="M467.3 528.9L452.4 537.4 467.3 528.9z" />
                <path className="st8" d="M390.9 570.2c.4.1.7.3 1.1.5l-.9-.5h-.2z" />
                <path
                  className="st9"
                  d="M294.9 733.6c67.1 38.7 149.8 38.7 216.9 0l226.7-128.4c48.2-27.8 48.2-97.5 0-125.3-66.6-38.5-148.7-38.5-215.4 0L294.9 609.1c-47.9 27.7-47.9 96.8 0 124.5z"
                />
                <path
                  className="st8"
                  d="M386.4 570.6c.4-.2.8-.4 1.3-.5h-.1l22.2-13c1.3-.9 2.8-1.4 4.4-1.1l.2-.1 2.8 1.6v10.1l-24.1 13.9-11.4-6.2c.1 0 2-3.1 4.7-4.7zM450.1 536.2c.4.1.7.3 1.1.5l-.9-.5h-.2z"
                />
                <path className="st8" d="M474.1 524.9L476.5 523.6 474.1 524.9z" />
                <path
                  className="st10"
                  d="M390.9 570.2c-1-.4-2.2-.4-3.2-.2.5.1 2.5.4 2.6.4-.1.1.2 0 .6-.2z"
                />
                <path className="st10" d="M391.9 570.7L393.2 571.4 391.9 570.7z" />
                <path
                  className="st11"
                  d="M841.9 563.2c.1-.4.3-.7.4-1.1-.7.9-1.4 1.9-2.2 2.8.6-.5 1.2-1.1 1.8-1.7z"
                />
                <path
                  className="st6"
                  d="M676.3 395.9c-12.9-1.7-25.9-2.6-38.9-2.6-34.7 0-69.5 6.1-102.4 18.3 46.1-12.8 93.8-18.1 141.3-15.7z"
                />
                <path
                  className="st11"
                  d="M378.7 501.3L249 576.1l.5.3c1.4-.9 2.9-1.8 4.4-2.7l124.8-72.4z"
                />
                <path
                  className="st6"
                  d="M838.9 566.1c.4-.4.8-.8 1.3-1.2.7-.9 1.5-1.9 2.2-2.8 4.9-12.6 7.6-26.4 7.6-40.8v-1.9c-.5 16.7-4.3 32.6-11.1 46.7zM824.4 444.9c-8.6-10.8-19.3-20-31.5-27.1h-.1c-95.9-55.3-215.1-55.3-311 .1L245.3 555c-5.5 3.2-10.6 6.8-15.3 10.8l2.9 1.6c3.9-3.1 8.1-5.9 12.5-8.4l236.4-137.2c90.3-52.1 201.1-55.2 293.8-9.3 5.8 2.9 11.6 6 17.3 9.3 9.9 5.7 18.7 12.8 26.3 21 19 20.5 30.2 47.8 30.6 76.7v-2c.1-26.9-9.2-52.5-25.4-72.6z"
                />
                <path
                  className="st6"
                  d="M824 557.6c-7.6 16.1-19.8 29.6-35.7 38.9-.7 1.2-1.5 2.4-2.3 3.6l15.4-8.9c8.9-7.6 16.1-16.8 21.4-27.2 6.5-12.8 10-27.3 10-42.6v-1.9c-.3 13.5-3.4 26.5-8.8 38.1zM784.4 432.6c-2.9-1.7-5.9-3.3-8.8-4.9-31.3-16.6-64.9-27.2-99.2-31.8-47.5-2.4-95.2 2.8-141.4 15.8-15.3 5.7-30.2 12.6-44.6 20.9L253.9 569.8c-2.6 1.5-5.1 3.1-7.5 4.9l2.7 1.5 129.7-74.9 111.6-64.8c45.3-26.2 96.2-39.3 147-39.3 41.3 0 82.6 8.6 121 25.9 8.8 4 17.5 8.4 26 13.3 29.3 16.9 47.7 48.6 48.4 83v-2c0-35-18.6-67.5-48.4-84.8z"
                />
                <path
                  className="st0"
                  d="M413 772c37.8 1.1 81.6-6.7 128.5-31.7 1.9-1 3.7-2.1 5.5-3.1l208.8-121c3.5-2 6.7-4.2 9.8-6.5l-212 123c-43.4 25-91.9 38.1-140.6 39.3z"
                />
                <path
                  className="st12"
                  d="M769.6 607.3c-.4.3-.8.7-1.3 1 .5-.3.9-.6 1.3-1zM764.9 611c-.5.3-1 .7-1.5 1 .5-.3 1-.7 1.5-1zM767.3 609.2c-.4.3-.9.7-1.4 1 .5-.3.9-.7 1.4-1zM762.4 612.7c-.6.4-1.1.7-1.7 1.1.5-.4 1.1-.7 1.7-1.1zM759.8 614.4c-.9.5-1.8 1.1-2.7 1.6.9-.6 1.8-1.1 2.7-1.6z"
                />
                <path
                  className="st12"
                  d="M759.8 614.4l.9-.6c.6-.4 1.1-.7 1.7-1.1.3-.2.7-.5 1-.7.5-.3 1-.7 1.5-1 .4-.3.7-.5 1.1-.8.5-.3.9-.7 1.4-1 .4-.3.7-.6 1.1-.8.4-.3.8-.7 1.3-1l.1-.1-4 2.3c-3.1 2.3-6.4 4.5-9.8 6.5l-208.8 121c-1.8 1.1-3.7 2.1-5.5 3.1 2-1.1 4-2.1 6-3.3l209.7-121c.5-.5 1.4-1 2.3-1.5zM787.2 597.1c-.8 1.2-1.6 2.5-2.5 3.7l1.3-.7c.8-1.2 1.6-2.4 2.3-3.6-.1.1-.2.1-.3.2l-.8.4z"
                />
                <path
                  className="st0"
                  d="M769.6 607.3c-.4.3-.8.7-1.3 1-.4.3-.7.6-1.1.8-.4.3-.9.7-1.4 1-.4.3-.7.5-1.1.8-.5.3-1 .7-1.5 1-.3.2-.7.5-1 .7-.6.4-1.1.7-1.7 1.1l-.9.6c-.9.5-1.8 1.1-2.7 1.6L547.5 737l1.2.5 236-136.8c.9-1.2 1.7-2.4 2.5-3.7l-17.5 10.2c0 .1-.1.1-.1.1z"
                />
                <path
                  className="st12"
                  d="M763.4 612c-.3.2-.7.5-1 .7.3-.2.6-.5 1-.7zM769.6 607.3l.1-.1-.1.1c.1 0 0 0 0 0zM760.7 613.8l-.9.6.9-.6zM765.9 610.2c-.4.3-.7.5-1.1.8.4-.3.8-.5 1.1-.8zM768.3 608.3c-.4.3-.7.6-1.1.8.4-.2.8-.5 1.1-.8z"
                />
                <path
                  className="st13"
                  d="M862.4 534.8c7.6-15.1 11.6-31.7 11.9-48.3-.3 16.6-4.3 33.2-11.9 48.3zM841.9 563.2c8.5-8.5 15.4-18.1 20.5-28.4-5.2 10.3-12 19.9-20.5 28.4zM808.4 381.3c-98.4-52.5-216.6-52.5-315.1 0 98.4-52.6 216.7-52.6 315.1 0zM874.3 486.5v-2.4c.1.7 0 1.5 0 2.4zM840.1 564.9c.6-.6 1.2-1.2 1.8-1.7-.6.6-1.2 1.2-1.8 1.7z"
                />
                <path className="st13" d="M784.7 600.7L786 600 786 600 784.7 600.7z" />
                <path
                  className="st13"
                  d="M801.4 591.1L801.4 591.1 816.8 582.2 816.8 582.2z"
                />
                <path
                  className="st14"
                  d="M948.9 488c.1-2.1.1-4.2.1-6.3 0 1.3 0 2.5-.1 3.8v2.5zM947.6 500.9l.3-.1c.5-4.3.8-8.5 1-12.8v-2.4c-.2 5.1-.6 10.2-1.3 15.3zM874.8 609L608 763.1l.8.3v1.7l266-153.6c43.5-25.1 67.9-66.9 73.1-110.6l-.3.1c-5.8 42.7-30.1 83.4-72.8 108zM150.1 520.1c-.7.4-1.3.8-2 1.2l.1.1L433.1 357c63.9-36.9 134.7-56.5 205.9-59-70.7 2-141.1 21.3-204.5 57.9L150.1 520.1z"
                />
                <path
                  className="st14"
                  d="M148.3 521.4l2 1.1 284.2-164.1c134.2-77.5 299.4-77.5 433.6 0 48.1 27.8 72.4 76.3 72.8 124.9.4-49.5-23.9-99.1-72.8-127.3-70.7-40.9-150.2-60.2-229.1-58-71.2 2.4-142 22-205.9 58.9L148.3 521.4z"
                />
                <path
                  className="st15"
                  d="M948.9 477.3v1.1c0 1.1 0 2.2.1 3.3 0-1.4 0-2.9-.1-4.4zM433.1 354.5c136.7-78.9 305-78.9 441.7 0 20.5 11.8 36.8 27.4 48.8 45.1 5.7 7.6 10.4 15.6 14.1 23.8-11.8-28.5-32.7-53.9-62.9-71.4-136.7-78.9-305-78.9-441.7 0L143.9 519l2.2 1.2 287-165.7zM868.1 608.2L603.3 761.1l2.4 1 262.4-151.5c30.1-17.4 50.9-42.9 62.3-71.4-1 1.7-2.1 3.3-3.2 5-11.9 25.5-31.6 48.1-59.1 64z"
                />
                <path
                  className="st15"
                  d="M948.9 478.5v-1.1c-.4-18.4-4.1-36.7-11.2-53.9-3.8-8.2-8.5-16.2-14.1-23.8 16 23.6 24.5 51.1 25.3 78.8zM940.9 486.8v-3.5 3.5zM939.5 502.4l.3-.1c.6-5.2 1-10.3 1-15.5-.1 5.3-.6 10.5-1.3 15.6zM927.2 544.2c1.1-1.6 2.2-3.3 3.2-5 4.8-11.9 7.9-24.3 9.4-36.9l-.3.1c-2 14.4-6.1 28.5-12.3 41.8zM150.1 520.1l284.4-164.2C497.9 319.3 568.2 300 639 298c81.2-2.8 163 16.9 235.8 58.9 20.5 11.8 36.8 26.4 48.8 42.6-12-17.7-28.2-33.2-48.8-45.1-136.7-78.9-305-78.9-441.7 0l-287 165.7 2 1.1c.7-.3 1.4-.7 2-1.1z"
                />
                <path
                  className="st15"
                  d="M948.5 485.4l.4-.1v.2c0-1.3.1-2.5.1-3.8 0-1.1 0-2.2-.1-3.3-.1 2.4-.2 4.7-.4 7z"
                />
                <path
                  className="st15"
                  d="M947.6 500.9c.7-5.1 1.1-10.2 1.3-15.3v-.2l-.4.1c-.5 5.3-1.3 10.6-2.5 15.8l1.6-.4z"
                />
                <path
                  className="st15"
                  d="M930.4 539.2c-11.4 28.5-32.2 54-62.3 71.4L605.8 762.1l2.2 1 266.8-154c42.7-24.7 67-65.3 72.8-108.1l-1.6.3c-3 13-8.2 25.8-15.6 37.9zM874.8 357C802 314.9 720.2 295.3 639 298c78.9-2.2 158.4 17.1 229.1 57.9 48.9 28.3 73.2 77.9 72.8 127.3v3.5l7.6-1.4c.2-2.3.4-4.6.4-6.9-.8-27.7-9.3-55.2-25.3-78.9-12-16.1-28.3-30.7-48.8-42.5zM939.9 502.4l6.1-1.1c1.2-5.2 2-10.5 2.5-15.8l-7.6 1.4c-.1 5.1-.4 10.3-1 15.5z"
                />
                <path
                  className="st15"
                  d="M946 501.2l-6.1 1.1c-1.5 12.6-4.7 25-9.4 36.9 7.3-12.1 12.5-24.9 15.5-38z"
                />
                <path
                  className="st10"
                  d="M450.1 536.2c-1-.4-2.2-.4-3.2-.2.5.1 2.5.4 2.6.4-.1.1.2 0 .6-.2z"
                />
                <path
                  className="st7"
                  d="M274.2 589.8l-1.1-.6c-25.1 18.1-37.2 46.5-36.2 74.5-.6-28 11.8-56.1 37.3-73.9z"
                />
                <path
                  className="st0"
                  d="M405.6 772.1c2.4 0 4.9 0 7.3-.1-79.7-2.2-132.8-43.6-132.8-43.6-64.6-74.1 9.5-130.2 9.5-130.2l-3.8-2-5 2.9c-27 15.6-41.6 41.9-43.8 69.1.2 1.8.4 3.7.6 5.5-.1-2.6 0-5.1.1-7.7 1.6 27.9 16.2 55.2 43.8 71.2 16.9 9.8 34.6 17.6 52.8 23.3 19 5.9 38.5 9.7 58.1 11.3 4.5.2 8.9.3 13.2.3z"
                />
                <path
                  className="st7"
                  d="M242.4 691.7c0-.1-.1-.2-.1-.4 0 .2.1.3.1.4zM243.4 694.2c0-.1-.1-.2-.1-.3 0 .1 0 .2.1.3zM240.7 686.7c0-.1-.1-.3-.1-.4 0 .1.1.3.1.4zM240 684.1c0-.1-.1-.2-.1-.4 0 .2 0 .3.1.4zM238.7 678.9v-.2.2zM238.2 676.3v-.1.1zM239.3 681.5c0-.1 0-.2-.1-.3.1.1.1.2.1.3zM241.5 689.2c0-.1-.1-.3-.1-.4.1.2.1.3.1.4zM272.2 731.3c-.4-.3-.9-.7-1.3-1 .4.3.9.6 1.3 1zM265.3 725.6c-.4-.3-.7-.7-1.1-1 .4.3.8.7 1.1 1zM269.8 729.4c-.4-.3-.8-.7-1.3-1 .5.4.9.7 1.3 1zM263.2 723.6l-1-1 1 1zM281 737c-.9-.5-1.8-1-2.6-1.6.8.6 1.7 1.1 2.6 1.6zM261.2 721.5c-.3-.3-.6-.7-1-1 .3.4.6.7 1 1zM277.5 734.9c-.6-.4-1.1-.7-1.7-1.1.5.3 1.1.7 1.7 1.1zM274.8 733.1c-.5-.3-1-.7-1.5-1 .5.3 1 .6 1.5 1zM244.4 696.5s0-.1-.1-.1l.1.1zM259.2 719.4c-.3-.3-.6-.7-.9-1 .3.4.6.7.9 1zM252.2 710.5c-.2-.2-.3-.5-.5-.7.2.3.3.5.5.7zM250.6 708.2c-.1-.1-.1-.2-.2-.3.1.1.2.2.2.3zM253.8 712.8l-.6-.9c.2.4.4.6.6.9zM257.3 717.3c-.3-.3-.5-.7-.8-1 .3.3.5.7.8 1zM255.5 715.1c-.2-.3-.5-.6-.7-.9.2.2.5.6.7.9zM267.5 727.5c-.4-.3-.8-.7-1.2-1 .5.4.8.7 1.2 1z"
                />
                <path
                  className="st7"
                  d="M281.7 737.2c-27.6-16-42.2-43.3-43.8-71.2-.1 2.6-.2 5.1-.1 7.7.1.8.3 1.7.4 2.5v.1c.1.8.3 1.6.5 2.4v.2c.2.8.3 1.5.5 2.3 0 .1 0 .2.1.3.2.8.4 1.5.6 2.2 0 .1.1.2.1.4.2.7.4 1.5.6 2.2 0 .1.1.3.1.4.2.7.4 1.4.7 2.2 0 .1.1.3.1.4.2.7.5 1.4.8 2.1 0 .1.1.2.1.4.3.7.5 1.4.8 2.2 0 .1.1.2.1.3.3.7.6 1.5.9 2.2 0 0 0 .1.1.1 1.7 3.9 3.7 7.7 6.1 11.4.1.1.1.2.2.3.3.5.7 1.1 1.1 1.6.2.2.3.5.5.7.3.5.7 1 1 1.4l.6.9c.3.4.7.9 1 1.3.2.3.5.6.7.9.3.4.7.8 1 1.2.3.3.5.7.8 1 .3.4.7.8 1 1.2.3.3.6.7.9 1 .3.4.7.7 1 1.1.3.3.6.7 1 1l1 1 1 1 1 1c.4.3.7.7 1.1 1 .3.3.7.6 1 .9.4.3.8.7 1.2 1 .3.3.7.6 1.1.9.4.3.8.7 1.3 1 .4.3.7.6 1.1.8.4.3.9.7 1.3 1 .3.3.7.5 1.1.8.5.3 1 .7 1.5 1 .3.2.7.5 1 .7.6.4 1.1.7 1.7 1.1l.9.6c.9.5 1.7 1.1 2.6 1.6 17.1 9.9 35.1 17.7 53.5 23.5-18.3-5.8-36-13.5-52.9-23.3z"
                />
                <path
                  className="st0"
                  d="M334.5 760.5c17.9 5.7 36.3 9.4 54.8 11.2 1.1.1 2.2.1 3.3.2-19.7-1.7-39.1-5.5-58.1-11.4zM237.2 668.2c.2 1.8.4 3.7.6 5.5-.3-1.9-.5-3.7-.6-5.5zM237.8 673.6c.1.8.3 1.7.4 2.5-.2-.8-.3-1.6-.4-2.5zM254.8 714.1c-.3-.4-.7-.9-1-1.3.3.5.7.9 1 1.3zM253.2 712c-.3-.5-.7-.9-1-1.4.3.4.7.9 1 1.4zM243.3 693.9c-.3-.7-.6-1.4-.8-2.2.2.7.5 1.5.8 2.2zM244.3 696.4c-.3-.7-.6-1.5-.9-2.2.3.7.6 1.4.9 2.2zM242.3 691.4c-.3-.7-.5-1.4-.8-2.1.3.6.5 1.4.8 2.1zM250.4 707.9c-2.3-3.7-4.3-7.5-6.1-11.4 1.8 3.9 3.8 7.7 6.1 11.4zM256.5 716.3c-.3-.4-.7-.8-1-1.2.3.4.7.8 1 1.2zM251.7 709.8c-.4-.5-.7-1.1-1.1-1.6.4.5.7 1.1 1.1 1.6zM275.8 733.8c-.3-.2-.7-.5-1-.7.3.2.6.4 1 .7zM268.6 728.4c-.4-.3-.7-.6-1.1-.9.4.3.7.6 1.1.9zM273.3 732.1c-.4-.3-.7-.5-1.1-.8.4.2.7.5 1.1.8zM278.3 735.4l-.9-.6c.4.3.6.4.9.6zM262.2 722.6l-1-1 1 1zM258.3 718.4c-.3-.4-.7-.8-1-1.2.3.5.7.8 1 1.2zM260.2 720.5c-.3-.4-.7-.7-1-1.1.3.4.7.8 1 1.1zM266.4 726.5c-.4-.3-.7-.6-1-.9.3.3.6.6 1 .9zM270.9 730.3c-.4-.3-.7-.6-1.1-.8.4.2.8.5 1.1.8zM239.2 681.2c-.2-.8-.4-1.5-.5-2.3.2.8.4 1.5.5 2.3zM238.7 678.7c-.2-.8-.3-1.6-.5-2.4.2.8.3 1.6.5 2.4zM240.6 686.3c-.2-.7-.4-1.5-.6-2.2.2.7.4 1.5.6 2.2zM239.9 683.8c-.2-.7-.4-1.5-.6-2.2.2.7.4 1.4.6 2.2zM241.4 688.8c-.2-.7-.5-1.4-.7-2.2.2.8.5 1.5.7 2.2zM236.9 663.7c0 .9 0 1.7.1 2.6v-.4c-.1-.8-.1-1.5-.1-2.2z"
                />
                <path
                  className="st0"
                  d="M237 665.8c0 .8.1 1.6.2 2.4 2.2-27.2 16.8-53.5 43.8-69.1l5-2.9-11.8-6.4c-25.4 17.8-37.8 46-37.3 73.9 0 .7 0 1.4.1 2.1z"
                />
                <path
                  className="st0"
                  d="M237 666.2c0 .8.1 1.6.1 2.5v-.5c-.1-.8-.1-1.6-.2-2.3.1.1.1.2.1.3z"
                />
                <path
                  className="st0"
                  d="M237 665.8c0 .8.1 1.6.2 2.4-.1-.8-.2-1.6-.2-2.4z"
                />
                <path
                  className="st12"
                  d="M413 772c-2.4.1-4.9.1-7.3.1-4.4 0-8.7-.1-13.1-.3 44.8 3.7 90.4-4.1 132.2-23.1 5.7-2.6 11.2-5.4 16.7-8.4-46.9 25.1-90.7 32.8-128.5 31.7z"
                />
                <path
                  className="st12"
                  d="M524.8 748.7c7.7-3.5 15.3-7.4 22.7-11.7l-6 3.3c-5.5 3-11.1 5.8-16.7 8.4zM392.6 771.8c-1.1 0-2.2-.1-3.3-.2 45.9 4.4 92.7-3.3 135.5-23-41.8 19.2-87.4 26.9-132.2 23.2z"
                />
                <path className="st13" d="M523.6 751.6z" />
                <path className="st13" d="M538.2 743.7L548.7 737.5 548.7 737.5z" />
                <path className="st14" d="M148.2 521.3l-3.9 2.4 4.1-2.4h-.2z" />
                <path
                  className="st0"
                  d="M608.8 809.7l-111.5-49.5s.3 4.4 51.5-22.7l59.3 25.5.7 46.7z"
                />
                <path className='opciones' id='mapas3'
                  d="M148.3 521.4l53.1 28.8 289.5-166.6s96-61.4 226.6-35.3c0 0 76.2 16.9 115.8 48.7 0 0 64.9 50.1 32.5 131.3 0 0-13.4 37.4-57.2 57.9L548.7 737.5l54.6 23.5 245.4-141.7s100.9-38.1 91.1-155.3c0 0 2.1-114.4-192.7-153.9 0 0-144.7-41.6-306.4 44.5L148.3 521.4z"
                  fill="#ffed00"
                />
                <path
                  className="st0"
                  d="M146.1 520.2l-2.5 54.7 100.6 52.9s7-22.2 30-38.1l-128.1-69.5z"
                />
                <path id='mapas1' className='opciones'
                  d="M295.1 609.1c-47.9 27.7-47.9 96.8 0 124.4 67.1 38.7 149.8 38.7 216.9 0l127.8-73.2L409 544.6l-113.9 64.5z"
                  fill="#009fe3"
                />
                <path
                  id="SVGID_x5F_00000147933743829379529940000000423730742157205895_x5F_"
                  className="st18"
                  d="M250.5 508.2l186.4-105.9S529 328.2 654 333.5"
                />
                <text>
                  <textPath
                    xlinkHref="#SVGID_x5F_00000147933743829379529940000000423730742157205895_x5F_"
                    startOffset="11.3525%"
                  >
                    <tspan className="st31 st32" fill="#1d1d1b">
                      {"PREFERENCIA"}
                    </tspan>
                  </textPath>
                </text>
                <path
                  id="SVGID_x5F_00000097492570320956236080000014213493829467205802_x5F_"
                  className="st18"
                  d="M604.1 367.9s282.7-7.4 243.5 171.5"
                />
                <text>
                  <textPath
                    xlinkHref="#SVGID_x5F_00000097492570320956236080000014213493829467205802_x5F_"
                    startOffset="17.163%"
                  >
                    <tspan className="st31" fontSize="28px">
                      {"PREFERENCIA"}
                    </tspan>
                  </textPath>
                </text>
                <path
                  className="st19"
                  d="M350.3 679.1L349.8 679.3 350.9 678.7 350.3 677.9z"
                />
                <path
                  className="st20"
                  d="M350.3 678.7L350.3 680 349.8 680.2 349.8 678.9z"
                />
                <path
                  className="st20"
                  d="M349.4 696.7L350.5 697.4 350.5 678.9 349.4 678.3z"
                />
                <path
                  className="st21"
                  d="M351.6 696.7L350.5 697.4 350.5 678.9 351.6 678.3z"
                />
                <path
                  className="st19"
                  d="M372.5 702.4L351.6 690.3 350.5 690.9 372.5 703.7z"
                />
                <path
                  className="st20"
                  d="M372.5 703.7L350.5 690.9 350.5 692.2 372.5 704.9z"
                />
                <g>
                  <path
                    className="st19"
                    d="M372.3 690.6L351.4 678.4 350.3 679.1 372.3 691.8z"
                  />
                  <path
                    className="st20"
                    d="M372.3 691.5L350.3 678.7 350.3 680 372.3 692.8z"
                  />
                </g>
                <g>
                  <path
                    className="st20"
                    d="M396.1 696.2L395 696.9 395 678.4 396.1 677.8z"
                  />
                  <path
                    className="st21"
                    d="M393.9 696.2L395 696.9 395 678.4 393.9 677.8z"
                  />
                  <path
                    className="st19"
                    d="M373.4 703L395.2 690.6 394.1 690 373.4 701.7z"
                  />
                  <path
                    className="st20"
                    d="M373.4 703L373.4 704.2 395.2 691.9 395.2 690.6z"
                  />
                  <g>
                    <path
                      className="st19"
                      d="M372.3 691.6L395.2 678.6 394.1 677.9 372.3 690.4z"
                    />
                    <path
                      className="st20"
                      d="M372.3 691.6L372.3 692.9 395.2 679.9 395.2 678.6z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    className="st20"
                    d="M373.4 709.5L372.3 710.1 372.3 691.7 373.4 691z"
                  />
                  <path
                    className="st21"
                    d="M371.2 709.5L372.3 710.1 372.3 691.7 371.2 691z"
                  />
                </g>
                <g>
                  <path
                    className="st22"
                    d="M372.4 685.5L396.1 671.7 372.4 658.5 348.8 672.3z"
                  />
                  <path
                    className="st23"
                    d="M348.8 672.3L348.8 678.5 372.4 691.7 372.4 685.5z"
                  />
                  <path
                    className="st24"
                    d="M396.1 671.7L396.1 677.9 372.4 691.7 372.4 685.5z"
                  />
                </g>
                <g>
                  <path
                    className="st21"
                    d="M279 714.7L277.8 715.3 277.8 696.9 279 696.3z"
                  />
                  <path
                    className="st20"
                    d="M276.7 714.7L277.8 715.3 277.8 696.9 276.7 696.3z"
                  />
                  <path
                    className="st19"
                    d="M262.2 717L276.7 708.6 277.8 709.3 262.2 718.3z"
                  />
                  <path
                    className="st21"
                    d="M262.2 718.3L277.8 709.3 277.8 710.6 262.2 719.6z"
                  />
                  <path
                    className="st19"
                    d="M395.3 715.5L380.8 707.1 379.7 707.8 395.3 716.8z"
                  />
                  <path
                    className="st21"
                    d="M395.3 716.8L379.7 707.8 379.7 709.1 395.3 718.1z"
                  />
                  <path
                    className="st19"
                    d="M262.2 705L276.7 696.6 277.8 697.3 262.2 706.3z"
                  />
                  <path
                    className="st21"
                    d="M262.2 706.3L277.8 697.3 277.8 698.6 262.2 707.6z"
                  />
                  <path
                    className="st21"
                    d="M396.4 722.3L395.3 723 395.3 704.6 396.4 703.9z"
                  />
                  <path
                    className="st20"
                    d="M394.2 722.3L395.3 723 395.3 704.6 394.2 703.9z"
                  />
                  <path
                    className="st20"
                    d="M261.1 724L262.2 724.6 262.2 706.2 261.1 705.6z"
                  />
                  <path
                    className="st21"
                    d="M263.4 724L262.2 724.6 262.2 706.2 263.4 705.6z"
                  />
                  <path
                    className="st19"
                    d="M281.7 729.8L262.1 718.4 263.2 717.8 281.7 728.5z"
                  />
                  <path
                    className="st20"
                    d="M281.7 729.8L281.7 731.1 262.1 719.7 262.1 718.4z"
                  />
                  <path
                    className="st19"
                    d="M281.7 717.7L262.1 706.4 263.2 705.7 281.7 716.5z"
                  />
                  <path
                    className="st20"
                    d="M281.7 717.7L281.7 719 262.1 707.7 262.1 706.4z"
                  />
                  <path
                    className="st19"
                    d="M377.5 726L394.2 716.3 395.3 717 377.5 727.3z"
                  />
                  <path
                    className="st21"
                    d="M377.5 727.3L395.3 717 395.3 718.3 377.5 728.6z"
                  />
                  <path
                    className="st19"
                    d="M377.5 714L394.2 704.3 395.3 704.9 377.5 715.2z"
                  />
                  <path
                    className="st21"
                    d="M377.5 715.2L395.3 704.9 395.3 706.2 377.5 716.5z"
                  />
                  <g>
                    <path
                      className="st21"
                      d="M378.2 732.9L377.1 733.5 377.1 715.1 378.2 714.4z"
                    />
                    <path
                      className="st20"
                      d="M376 732.9L377.1 733.5 377.1 715.1 376 714.4z"
                    />
                  </g>
                  <g>
                    <path
                      className="st19"
                      d="M361.5 735.2L376 726.8 377.1 727.4 361.5 736.5z"
                    />
                    <path
                      className="st21"
                      d="M361.5 736.5L377.1 727.4 377.1 728.7 361.5 737.8z"
                    />
                  </g>
                  <g>
                    <path
                      className="st19"
                      d="M361.5 723.2L376 714.8 377.1 715.4 361.5 724.5z"
                    />
                    <path
                      className="st21"
                      d="M361.5 724.5L377.1 715.4 377.1 716.7 361.5 725.7z"
                    />
                  </g>
                  <g>
                    <path
                      className="st21"
                      d="M362.4 742L361.3 742.6 361.3 724.2 362.4 723.6z"
                    />
                    <path
                      className="st20"
                      d="M360.2 742L361.3 742.6 361.3 724.2 360.2 723.6z"
                    />
                  </g>
                  <g>
                    <path
                      className="st19"
                      d="M345 744.7L360.2 735.9 361.3 736.6 345 746z"
                    />
                    <path
                      className="st21"
                      d="M345 746L361.3 736.6 361.3 737.9 345 747.3z"
                    />
                  </g>
                  <g>
                    <path
                      className="st19"
                      d="M345 732.7L360.2 723.9 361.3 724.6 345 734z"
                    />
                    <path
                      className="st21"
                      d="M345 734L361.3 724.6 361.3 725.9 345 735.3z"
                    />
                  </g>
                  <g>
                    <path
                      className="st21"
                      d="M346 751.5L344.9 752.2 344.9 733.8 346 733.1z"
                    />
                    <path
                      className="st20"
                      d="M343.8 751.5L344.9 752.2 344.9 733.8 343.8 733.1z"
                    />
                  </g>
                  <g>
                    <path
                      className="st19"
                      d="M329.3 753.9L343.8 745.5 344.9 746.1 329.3 755.1z"
                    />
                    <path
                      className="st21"
                      d="M329.3 755.1L344.9 746.1 344.9 747.4 329.3 756.4z"
                    />
                  </g>
                  <g>
                    <path
                      className="st19"
                      d="M329.3 741.8L343.8 733.5 344.9 734.1 329.3 743.1z"
                    />
                    <path
                      className="st21"
                      d="M329.3 743.1L344.9 734.1 344.9 735.4 329.3 744.4z"
                    />
                  </g>
                  <g>
                    <path
                      className="st20"
                      d="M280.8 735.3L281.9 736 281.9 717.5 280.8 716.9z"
                    />
                    <path
                      className="st21"
                      d="M283 735.3L281.9 736 281.9 717.5 283 716.9z"
                    />
                    <path
                      className="st19"
                      d="M329.3 753.5L282.8 729.1 281.7 729.7 329.3 754.8z"
                    />
                    <path
                      className="st20"
                      d="M329.3 754.8L281.7 729.7 281.7 731 329.3 756.1z"
                    />
                    <g>
                      <path
                        className="st19"
                        d="M329.3 741.5L282.8 717 281.7 717.7 329.3 742.8z"
                      />
                      <path
                        className="st20"
                        d="M329.3 742.8L281.7 717.7 281.7 719 329.3 744z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      className="st21"
                      d="M330.6 760.3L329.5 761 329.5 741.3 330.6 740.7z"
                    />
                    <path
                      className="st20"
                      d="M328.4 760.3L329.5 761 329.5 741.3 328.4 740.7z"
                    />
                  </g>
                  <g>
                    <path
                      className="st24"
                      d="M329.5 743L396.4 704.4 353.6 679.6 286.7 718.3z"
                    />
                    <path
                      className="st22"
                      d="M329.5 736.8L396.4 698.2 328 661.1 261.1 699.8z"
                    />
                    <path
                      className="st23"
                      d="M261.1 699.7L261.1 706 329.5 743 329.5 736.8z"
                    />
                    <path
                      className="st24"
                      d="M396.4 698.2L396.4 704.4 329.5 743 329.5 736.8z"
                    />
                  </g>
                  <g>
                    <path
                      className="st21"
                      d="M401.6 703.2L408.5 699.2 408.5 718.8 401.6 722.8z"
                    />
                    <path
                      className="st24"
                      d="M408.3 699.6L401.8 703.3 401.9 722.4 408.3 718.7z"
                    />
                    <path
                      className="st21"
                      d="M401.6 712.5L408.5 708.6 408.5 708.9 401.6 712.8z"
                    />
                    <path
                      className="st23"
                      d="M401.6 703.2L396.4 700.3 396.4 719.9 401.6 722.8z"
                    />
                    <path
                      className="st23"
                      d="M398.1 701.6L396.8 700.9 396.8 703.7 398.1 704.4z"
                    />
                    <path
                      className="st25"
                      d="M403.3 696.3L408.5 699.2 401.6 703.2 396.4 700.3z"
                    />
                  </g>
                  <g>
                    <path
                      className="st25"
                      d="M370.7 700.8L375.7 703.7 381.6 700.3 376.5 697.4z"
                    />
                    <path
                      className="st24"
                      d="M381.6 700.3L381.6 705.1 375.7 708.5 375.7 703.7z"
                    />
                    <path
                      className="st21"
                      d="M381.3 700.9L381.3 704.9 376.1 707.8 376.1 703.9z"
                    />
                    <path
                      className="st20"
                      d="M381.6 701.2L381.6 705.1 376.4 708.1 376.4 704.2z"
                    />
                    <path
                      className="st23"
                      d="M370.7 700.8L370.7 705.6 375.7 708.5 375.7 703.7z"
                    />
                    <path
                      className="st19"
                      d="M381.3 700.9L381.6 701.2 376.4 704.2 376.1 703.9z"
                    />
                    <path
                      className="st21"
                      d="M376.1 707.8L376.4 708.1 376.4 704.2 376.1 703.9z"
                    />
                    <path
                      className="st21"
                      d="M380.9 705.2L380.7 705.4 380.7 702 380.9 701.8z"
                    />
                    <path
                      className="st21"
                      d="M380.2 705.7L380 705.8 380 702.4 380.2 702.3z"
                    />
                    <path
                      className="st21"
                      d="M379.5 706.1L379.2 706.3 379.2 702.8 379.5 702.7z"
                    />
                    <path
                      className="st21"
                      d="M378.8 706.5L378.5 706.7 378.5 703.3 378.8 703.1z"
                    />
                    <path
                      className="st21"
                      d="M378.1 706.9L377.8 707 377.8 703.6 378.1 703.5z"
                    />
                    <path
                      className="st21"
                      d="M377.4 707.3L377.1 707.5 377.1 704 377.4 703.9z"
                    />
                  </g>
                  <g>
                    <path
                      className="st25"
                      d="M334.5 721.8L339.6 724.7 345.5 721.3 340.4 718.4z"
                    />
                    <path
                      className="st24"
                      d="M345.5 721.3L345.5 726.1 339.6 729.5 339.6 724.7z"
                    />
                    <path
                      className="st21"
                      d="M345.1 721.9L345.1 725.8 340 728.8 340 724.9z"
                    />
                    <path
                      className="st20"
                      d="M345.4 722.2L345.4 726.1 340.3 729.1 340.3 725.1z"
                    />
                    <path
                      className="st23"
                      d="M334.5 721.8L334.5 726.6 339.6 729.5 339.6 724.7z"
                    />
                    <path
                      className="st19"
                      d="M345.1 721.9L345.4 722.2 340.3 725.1 340 724.9z"
                    />
                    <path
                      className="st21"
                      d="M340 728.8L340.3 729.1 340.3 725.1 340 724.9z"
                    />
                    <path
                      className="st21"
                      d="M344.8 726.2L344.5 726.4 344.5 723 344.8 722.8z"
                    />
                    <path
                      className="st21"
                      d="M344.1 726.7L343.8 726.8 343.8 723.4 344.1 723.2z"
                    />
                    <path
                      className="st21"
                      d="M343.3 727.1L343.1 727.2 343.1 723.8 343.3 723.7z"
                    />
                    <path
                      className="st21"
                      d="M342.6 727.5L342.4 727.7 342.4 724.2 342.6 724.1z"
                    />
                    <path
                      className="st21"
                      d="M341.9 727.9L341.6 728 341.6 724.6 341.9 724.4z"
                    />
                    <path
                      className="st21"
                      d="M341.2 728.3L340.9 728.4 340.9 725 341.2 724.9z"
                    />
                  </g>
                  <g>
                    <path
                      className="st23"
                      d="M244.7 737.2L257 744.1 244.8 751 232.5 744z"
                    />
                    <path
                      className="st22"
                      d="M244.7 736.4L257 743.4 244.8 750.2 232.5 743.3z"
                    />
                    <path
                      className="st23"
                      d="M244.8 750.2L244.8 751 257 744.1 257 743.4z"
                    />
                    <path
                      className="st24"
                      d="M232.5 743.3L232.5 744 244.8 751 244.8 750.2z"
                    />
                    <path
                      className="st21"
                      d="M236.9 743.4L235.6 744.1 235.6 617.5 236.9 616.7z"
                    />
                    <path
                      className="st20"
                      d="M234.4 743.4L235.6 744.1 235.6 616.8 234.4 616.1z"
                    />
                    <g>
                      <path
                        className="st21"
                        d="M252.8 743.4L254 744.1 254 617 252.8 616.3z"
                      />
                      <path
                        className="st20"
                        d="M255.2 743.4L254 744.1 254 617 255.2 616.3z"
                      />
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M253 724.6l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                      />
                      <path
                        className="st21"
                        d="M253.1 724.6c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM253.1 718.7c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.2.1-.4 0-.5z"
                      />
                      <path
                        className="st21"
                        d="M253.1 731.1c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.1.1-.3 0-.5z"
                      />
                      <g>
                        <path
                          className="st21"
                          d="M253 737l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M253.1 737.1c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4z"
                        />
                        <path
                          className="st21"
                          d="M245.4 748.4l8-4.6c-.1-.3-.2-.6-.4-.5l-7.6 4.4v.7z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M253 699.6l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M253.1 699.7c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM253.1 693.7c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.1.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M253.1 706.2c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.1.1-.3 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M253 712.1l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M253.1 712.2c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M253 674.7l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M253.1 674.8c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM253.1 668.8c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.1.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M253.1 681.3c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.2.1-.4 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M253 687.2l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M253.1 687.2c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2 0 .2-.2 0-.4z"
                          />
                        </g>
                        <g>
                          <path
                            className="st21"
                            d="M253 649.8l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M253.1 649.9c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM253.1 643.9c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.2.1-.4 0-.5z"
                          />
                          <path
                            className="st21"
                            d="M253.1 656.4c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.2.1-.4 0-.5z"
                          />
                          <g>
                            <path
                              className="st21"
                              d="M253 662.3l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M253.1 662.3c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M236.3 724.6l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                      />
                      <path
                        className="st21"
                        d="M236.2 724.6c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM236.2 718.7c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                      />
                      <path
                        className="st21"
                        d="M236.2 731.1c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                      />
                      <g>
                        <path
                          className="st21"
                          d="M236.3 737l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M236.2 737.1c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4z"
                        />
                        <path
                          className="st21"
                          d="M243.9 748.4l-8-4.6c.1-.3.2-.6.4-.5l7.6 4.4v.7z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M236.3 699.6l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M236.2 699.7c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM236.2 693.7c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M236.2 706.2c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M236.3 712.1l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M236.2 712.2c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M236.3 674.7l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M236.2 674.8c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM236.2 668.8c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M236.2 681.3c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M236.3 687.2l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M236.2 687.2c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2 0-.2-.2 0-.4z"
                          />
                        </g>
                        <g>
                          <path
                            className="st21"
                            d="M236.3 649.8l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M236.2 649.9c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM236.2 643.9c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                          />
                          <path
                            className="st21"
                            d="M236.2 656.4c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5zM253 637.4l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M253.1 637.4c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM253.1 631.5c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.2.1-.4 0-.5z"
                          />
                          <path
                            className="st21"
                            d="M253.1 643.9c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.1.1-.3 0-.5z"
                          />
                          <g>
                            <path
                              className="st21"
                              d="M236.3 637.4l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M236.2 637.4c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM236.2 631.5c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                            />
                            <path
                              className="st21"
                              d="M236.2 643.9c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M253 624.9l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M253.1 625c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM253.1 619c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.1.1-.3 0-.5z"
                            />
                            <path
                              className="st21"
                              d="M253.1 631.5c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c0-.2.1-.4 0-.5z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M236.3 624.9l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M236.2 625c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM236.2 619c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                            />
                            <path
                              className="st21"
                              d="M236.2 631.5c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M236.3 662.3l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M236.2 662.3c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M246.1 748.7L244.8 749.4 244.8 621.6 246.1 620.9z"
                      />
                      <path
                        className="st21"
                        d="M244.8 748L243.6 748.7 243.6 649.4 244.8 648.7z"
                      />
                      <path
                        className="st20"
                        d="M243.6 748.7L244.8 749.4 244.8 621.6 243.6 620.9z"
                      />
                    </g>
                    <g>
                      <path
                        className="st22"
                        d="M244.7 608.5L257 615.4 244.8 622.3 232.5 615.4z"
                      />
                      <path
                        className="st23"
                        d="M244.8 622.3L244.8 623 257 616.2 257 615.4z"
                      />
                      <path
                        className="st24"
                        d="M232.5 615.4L232.5 616.1 244.8 623 244.8 622.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      className="st23"
                      d="M339.4 780.1L351.7 787 339.5 793.8 327.2 786.9z"
                    />
                    <path
                      className="st22"
                      d="M339.4 779.3L351.7 786.2 339.5 793.1 327.2 786.2z"
                    />
                    <path
                      className="st23"
                      d="M339.5 793.1L339.5 793.8 351.7 787 351.7 786.2z"
                    />
                    <path
                      className="st24"
                      d="M327.2 786.2L327.2 786.9 339.5 793.8 339.5 793.1z"
                    />
                    <path
                      className="st21"
                      d="M331.6 786.2L330.4 787 330.4 660.3 331.6 659.6z"
                    />
                    <path
                      className="st20"
                      d="M329.1 786.2L330.4 787 330.4 659.7 329.1 659z"
                    />
                    <g>
                      <path
                        className="st21"
                        d="M347.5 786.2L348.7 787 348.7 659.9 347.5 659.2z"
                      />
                      <path
                        className="st20"
                        d="M349.9 786.2L348.7 787 348.7 659.9 349.9 659.2z"
                      />
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M347.7 767.5l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                      />
                      <path
                        className="st21"
                        d="M347.8 767.5c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM347.8 761.6c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.2.1-.4 0-.5z"
                      />
                      <path
                        className="st21"
                        d="M347.8 774c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.1.1-.3 0-.5z"
                      />
                      <g>
                        <path
                          className="st21"
                          d="M347.7 779.9l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M347.8 780c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4z"
                        />
                        <path
                          className="st21"
                          d="M340.1 791.3l8-4.6c-.1-.3-.2-.6-.4-.5l-7.6 4.4v.7z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M347.7 742.5l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M347.8 742.6c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM347.8 736.6c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.1.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M347.8 749.1c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.1.1-.3 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M347.7 755l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M347.8 755.1c-.1-.1-.4-.1-.5.1L340 765v1.1l7.8-10.6c.2-.1.2-.3 0-.4z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M347.7 717.6l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M347.8 717.7c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM347.8 711.7c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.1.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M347.8 724.2c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.2.1-.4 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M347.7 730.1l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M347.8 730.1c-.1-.1-.4-.1-.5.1L340 740v1.1l7.8-10.6c.2-.1.2-.3 0-.4z"
                          />
                        </g>
                        <g>
                          <path
                            className="st21"
                            d="M347.7 692.7l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M347.8 692.7c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2 0 .2-.3 0-.4zM347.8 686.8c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.2.1-.4 0-.5z"
                          />
                          <path
                            className="st21"
                            d="M347.8 699.2c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.1.1-.3 0-.5z"
                          />
                          <g>
                            <path
                              className="st21"
                              d="M347.7 705.1l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M347.8 705.2c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M331 767.5l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                      />
                      <path
                        className="st21"
                        d="M330.9 767.5c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM330.9 761.6c.1-.2.3-.2.4-.1l7.2 4.2v.8L331 762c-.1-.1-.2-.3-.1-.4z"
                      />
                      <path
                        className="st21"
                        d="M330.9 774c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                      />
                      <g>
                        <path
                          className="st21"
                          d="M331 779.9l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M330.9 780c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4z"
                        />
                        <path
                          className="st21"
                          d="M338.6 791.3l-8-4.6c.1-.3.2-.6.4-.5l7.6 4.4v.7z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M331 742.5l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M330.9 742.6c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM330.9 736.6c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M330.9 749.1c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M331 755l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M330.9 755.1c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M331 717.6l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                        />
                        <path
                          className="st21"
                          d="M330.9 717.7c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM330.9 711.7c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                        />
                        <path
                          className="st21"
                          d="M330.9 724.2c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M331 730.1l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M330.9 730.1c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4z"
                          />
                        </g>
                        <g>
                          <path
                            className="st21"
                            d="M331 692.7l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M330.9 692.7c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2 0-.2-.3 0-.4zM330.9 686.8c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                          />
                          <path
                            className="st21"
                            d="M330.9 699.2c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5zM347.7 680.3l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                          />
                          <path
                            className="st21"
                            d="M347.8 680.3c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM347.8 674.4c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.2.1-.4 0-.5z"
                          />
                          <path
                            className="st21"
                            d="M347.8 686.8c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.1.1-.3 0-.5z"
                          />
                          <g>
                            <path
                              className="st21"
                              d="M331 680.3l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M330.9 680.3c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM330.9 674.4c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                            />
                            <path
                              className="st21"
                              d="M330.9 686.8c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.1-.1-.3 0-.5z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M347.7 667.8l-7.6-1.3v.7l7.5 1.3h.1c.2 0 .3-.1.3-.3 0-.2-.1-.4-.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M347.8 667.9c-.1-.1-.4-.1-.5.1l-7.3 9.8v1.1l7.8-10.6c.2-.1.2-.3 0-.4zM347.8 661.9c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.2.1-.4 0-.5z"
                            />
                            <path
                              className="st21"
                              d="M347.8 674.4c-.1-.2-.3-.2-.4-.1l-7.2 4.2v.8l7.6-4.4c.1-.2.1-.4 0-.5z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M331 667.8l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.4.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M330.9 667.9c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4zM330.9 661.9c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                            />
                            <path
                              className="st21"
                              d="M330.9 674.4c.1-.2.3-.2.4-.1l7.2 4.2v.8l-7.6-4.4c0-.2-.1-.4 0-.5z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M331 705.1l7.6-1.3v.7l-7.5 1.3h-.1c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.4z"
                            />
                            <path
                              className="st21"
                              d="M330.9 705.2c.1-.1.4-.1.5.1l7.3 9.8v1.1l-7.8-10.6c-.2-.1-.2-.3 0-.4z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M340.8 791.6L339.5 792.3 339.5 664.5 340.8 663.8z"
                      />
                      <path
                        className="st21"
                        d="M339.5 790.9L338.3 791.6 338.3 692.3 339.5 691.6z"
                      />
                      <path
                        className="st20"
                        d="M338.3 791.6L339.5 792.3 339.5 664.5 338.3 663.8z"
                      />
                    </g>
                    <g>
                      <path
                        className="st22"
                        d="M339.4 651.4L351.7 658.3 339.5 665.2 327.2 658.3z"
                      />
                      <path
                        className="st23"
                        d="M339.5 665.2L339.5 665.9 351.7 659.1 351.7 658.3z"
                      />
                      <path
                        className="st24"
                        d="M327.2 658.3L327.2 659 339.5 665.9 339.5 665.2z"
                      />
                    </g>
                    <g>
                      <path
                        className="st26"
                        d="M350.9 676.5L350.6 676.8 351.4 677.4 351.7 677z"
                      />
                      <path
                        className="st26"
                        d="M355.7 670.2L356.5 670.8 356.2 671 355.5 670.5z"
                      />
                      <path
                        className="st26"
                        d="M355.7 675.5c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2s3.7-2.4 5-1.4c1.4 1 1.3 3.4-.1 5.2z"
                      />
                      <path
                        className="st21"
                        d="M356.4 676c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2 1.4-1.8 3.7-2.4 5-1.4 1.3 1 1.3 3.4-.1 5.2z"
                      />
                      <path
                        className="st23"
                        d="M360 674.4l-4-3.1c-.5-.4-1-.5-1.7-.4l5.2 4 .5-.5zM351.1 673.9l5.8 4.5.8-1.1-6.1-4.7c-.2.5-.4.9-.5 1.3zM351.8 676.8l4 3.1.6-.8-5.4-4.2c0 .8.2 1.5.8 1.9zM353.4 671.2c-.4.2-.8.5-1.1.8l6 4.6.8-1.1-5.7-4.3z"
                      />
                      <path
                        className="st23"
                        d="M351 674.9l5.4 4.2.5-.7-5.8-4.5c-.1.4-.1.7-.1 1z"
                      />
                      <path
                        className="st24"
                        d="M351 674.9l5.4 4.2.5-.7-5.8-4.5c-.1.4-.1.7-.1 1z"
                      />
                      <path
                        className="st23"
                        d="M359.6 674.9l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st24"
                        d="M359.6 674.9l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st23"
                        d="M351.9 672.5l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.2-.4.4z"
                      />
                      <path
                        className="st24"
                        d="M351.9 672.5l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.2-.4.4z"
                      />
                      <path
                        className="st22"
                        d="M360 678.7c-1.2 1.5-3 2-4.2 1.2-1.1-.9-1.1-2.8 0-4.3 1.2-1.5 3-2 4.2-1.2 1.2.9 1.2 2.8 0 4.3z"
                      />
                      <g>
                        <path
                          className="st23"
                          d="M359.6 674.9c-.1-.1-.2-.1-.3-.2.6.8.5 2.1-.3 3.2s-2.1 1.5-3 1.1l.3.3c.9.7 2.4.3 3.3-.9.9-1.2.9-2.8 0-3.5z"
                        />
                        <path
                          className="st27"
                          d="M359 677.9c.8-1.1.9-2.4.3-3.2-.9-.4-2.2 0-3 1.1-.8 1.1-.9 2.4-.3 3.2.9.4 2.1 0 3-1.1z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M353.2 671.7s.9-.5 1.2-.7c.7-.4.8.4.8.4l-2.1 1.2c-.6.3-1 1.2-1 2v3.4c0 .8.5 1.1 1 .8l.6-.4.6.5-1.3.7c-.9.5-1.7 0-1.7-1.3V675c.2-1.3 1-2.7 1.9-3.3z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 676.8L352 675.4 351.6 674.7 349.3 675.9z"
                        />
                        <path
                          className="st24"
                          d="M352.1 675.2c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .1.2.3.2.5z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 680.4L352 679 351.6 678.3 349.3 679.6z"
                        />
                        <path
                          className="st24"
                          d="M352.1 678.8c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .1.2.3.2.5z"
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        className="st26"
                        d="M350.9 687.6L350.6 687.9 351.4 688.5 351.7 688.1z"
                      />
                      <path
                        className="st26"
                        d="M355.7 681.3L356.5 681.9 356.2 682.1 355.5 681.6z"
                      />
                      <path
                        className="st26"
                        d="M355.7 686.6c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2 1.4-1.8 3.7-2.4 5-1.4 1.4 1.1 1.3 3.4-.1 5.2z"
                      />
                      <path
                        className="st21"
                        d="M356.4 687.1c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2 1.4-1.8 3.7-2.4 5-1.4 1.3 1.1 1.3 3.4-.1 5.2z"
                      />
                      <path
                        className="st23"
                        d="M360 685.5l-4-3.1c-.5-.4-1-.5-1.7-.4l5.2 4 .5-.5zM351.1 685.1l5.8 4.5.8-1.1-6.1-4.7c-.2.4-.4.8-.5 1.3zM351.8 687.9l4 3.1.6-.8-5.4-4.2c0 .8.2 1.5.8 1.9zM353.4 682.3c-.4.2-.8.5-1.1.8l6 4.6.8-1.1-5.7-4.3z"
                      />
                      <path
                        className="st23"
                        d="M351 686l5.4 4.2.5-.7-5.8-4.5c-.1.4-.1.7-.1 1z"
                      />
                      <path
                        className="st24"
                        d="M351 686l5.4 4.2.5-.7-5.8-4.5c-.1.4-.1.7-.1 1z"
                      />
                      <path
                        className="st23"
                        d="M359.6 686.1l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st24"
                        d="M359.6 686.1l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st23"
                        d="M351.9 683.6l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.2-.4.4z"
                      />
                      <path
                        className="st24"
                        d="M351.9 683.6l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.2-.4.4z"
                      />
                      <path
                        className="st22"
                        d="M360 689.9c-1.2 1.5-3 2-4.2 1.2-1.1-.9-1.1-2.8 0-4.3 1.2-1.5 3-2 4.2-1.2 1.2.8 1.2 2.7 0 4.3z"
                      />
                      <g>
                        <path
                          className="st23"
                          d="M359.6 686.1c-.1-.1-.2-.1-.3-.2.6.8.5 2.1-.3 3.2s-2.1 1.5-3 1.1l.3.3c.9.7 2.4.3 3.3-.9.9-1.3.9-2.8 0-3.5z"
                        />
                        <path
                          className="st27"
                          d="M359 689.1c.8-1.1.9-2.4.3-3.2-.9-.4-2.2 0-3 1.1-.8 1.1-.9 2.4-.3 3.2.9.4 2.1-.1 3-1.1z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M353.2 682.9s.9-.5 1.2-.7c.7-.4.8.4.8.4l-2.1 1.2c-.6.3-1 1.2-1 2v3.4c0 .8.5 1.1 1 .8l.6-.4.6.5-1.3.7c-.9.5-1.7 0-1.7-1.3v-3.4c.2-1.2 1-2.7 1.9-3.2z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 687.9L352 686.5 351.6 685.8 349.3 687.1z"
                        />
                        <path
                          className="st24"
                          d="M352.1 686.3c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .1.2.3.2.5z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 691.6L352 690.1 351.6 689.4 349.3 690.7z"
                        />
                        <path
                          className="st24"
                          d="M352.1 689.9c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .1.2.4.2.5z"
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        className="st26"
                        d="M350.9 698.7L350.6 698.9 351.4 699.5 351.7 699.1z"
                      />
                      <path
                        className="st26"
                        d="M355.7 692.3L356.5 692.9 356.2 693.1 355.5 692.6z"
                      />
                      <path
                        className="st26"
                        d="M355.7 697.6c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2 1.4-1.8 3.7-2.4 5-1.4 1.4 1.1 1.3 3.4-.1 5.2z"
                      />
                      <path
                        className="st21"
                        d="M356.4 698.1c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2 1.4-1.8 3.7-2.4 5-1.4 1.3 1.1 1.3 3.4-.1 5.2z"
                      />
                      <path
                        className="st23"
                        d="M360 696.5l-4-3.1c-.5-.4-1-.5-1.7-.4l5.2 4 .5-.5zM351.1 696.1l5.8 4.5.8-1.1-6.1-4.7c-.2.4-.4.9-.5 1.3zM351.8 699l4 3.1.6-.8-5.4-4.3c0 .8.2 1.5.8 2zM353.4 693.4c-.4.2-.8.5-1.1.8l6 4.6.8-1.1-5.7-4.3z"
                      />
                      <path
                        className="st23"
                        d="M351 697l5.4 4.2.5-.7-5.8-4.5c-.1.4-.1.7-.1 1z"
                      />
                      <path
                        className="st24"
                        d="M351 697l5.4 4.2.5-.7-5.8-4.5c-.1.4-.1.7-.1 1z"
                      />
                      <path
                        className="st23"
                        d="M359.6 697.1l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st24"
                        d="M359.6 697.1l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st23"
                        d="M351.9 694.6l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.3-.4.4z"
                      />
                      <path
                        className="st24"
                        d="M351.9 694.6l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.3-.4.4z"
                      />
                      <path
                        className="st22"
                        d="M360 700.9c-1.2 1.5-3 2-4.2 1.2-1.1-.9-1.1-2.8 0-4.3 1.2-1.5 3-2 4.2-1.2 1.2.8 1.2 2.8 0 4.3z"
                      />
                      <g>
                        <path
                          className="st23"
                          d="M359.6 697.1c-.1-.1-.2-.1-.3-.2.6.8.5 2.1-.3 3.2s-2.1 1.5-3 1.1l.3.3c.9.7 2.4.3 3.3-.9.9-1.3.9-2.8 0-3.5z"
                        />
                        <path
                          className="st27"
                          d="M359 700.1c.8-1.1.9-2.4.3-3.2-.9-.4-2.2 0-3 1.1-.8 1.1-.9 2.4-.3 3.2.9.4 2.1-.1 3-1.1z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M353.2 693.9s.9-.5 1.2-.7c.7-.4.8.4.8.4l-2.1 1.2c-.6.3-1 1.2-1 2v3.4c0 .8.5 1.1 1 .8l.6-.4.6.5-1.3.7c-.9.5-1.7 0-1.7-1.3v-3.4c.2-1.2 1-2.7 1.9-3.2z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 699L352 697.5 351.6 696.8 349.3 698.1z"
                        />
                        <path
                          className="st24"
                          d="M352.1 697.3c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .1.2.3.2.5z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 702.6L352 701.1 351.6 700.5 349.3 701.7z"
                        />
                        <path
                          className="st24"
                          d="M352.1 700.9c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .2.2.4.2.5z"
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        className="st26"
                        d="M350.9 709.8L350.6 710.1 351.4 710.6 351.7 710.3z"
                      />
                      <path
                        className="st26"
                        d="M355.7 703.5L356.5 704 356.2 704.2 355.5 703.8z"
                      />
                      <path
                        className="st26"
                        d="M355.7 708.8c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2s3.7-2.4 5-1.4c1.4 1 1.3 3.3-.1 5.2z"
                      />
                      <path
                        className="st21"
                        d="M356.4 709.3c-1.4 1.8-3.7 2.4-5 1.4-1.4-1.1-1.4-3.4.1-5.2 1.4-1.8 3.7-2.4 5-1.4 1.3 1 1.3 3.3-.1 5.2z"
                      />
                      <path
                        className="st23"
                        d="M360 707.7l-4-3.1c-.5-.4-1-.5-1.7-.4l5.2 4 .5-.5zM351.1 707.2l5.8 4.5.8-1.1-6.1-4.7c-.2.5-.4.9-.5 1.3zM351.8 710.1l4 3.1.6-.8-5.4-4.2c0 .7.2 1.4.8 1.9zM353.4 704.5c-.4.2-.8.5-1.1.8l6 4.6.8-1.1-5.7-4.3z"
                      />
                      <path
                        className="st23"
                        d="M351 708.2l5.4 4.2.5-.7-5.8-4.5c-.1.3-.1.7-.1 1z"
                      />
                      <path
                        className="st24"
                        d="M351 708.2l5.4 4.2.5-.7-5.8-4.5c-.1.3-.1.7-.1 1z"
                      />
                      <path
                        className="st23"
                        d="M359.6 708.2l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st24"
                        d="M359.6 708.2l-5.2-4c-.3 0-.7.1-1 .3l5.7 4.4.5-.7z"
                      />
                      <path
                        className="st23"
                        d="M351.9 705.7l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.3-.4.4z"
                      />
                      <path
                        className="st24"
                        d="M351.9 705.7l-.2.2 6.1 4.7.5-.7-6-4.6c-.2.1-.3.3-.4.4z"
                      />
                      <path
                        className="st22"
                        d="M360 712c-1.2 1.5-3 2-4.2 1.2-1.1-.9-1.1-2.8 0-4.3 1.2-1.5 3-2 4.2-1.2 1.2.8 1.2 2.8 0 4.3z"
                      />
                      <g>
                        <path
                          className="st23"
                          d="M359.6 708.2c-.1-.1-.2-.1-.3-.2.6.8.5 2.1-.3 3.2s-2.1 1.5-3 1.1l.3.3c.9.7 2.4.3 3.3-.9.9-1.2.9-2.8 0-3.5z"
                        />
                        <path
                          className="st27"
                          d="M359 711.2c.8-1.1.9-2.4.3-3.2-.9-.4-2.2 0-3 1.1-.8 1.1-.9 2.4-.3 3.2.9.4 2.1 0 3-1.1z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M353.2 705s.9-.5 1.2-.7c.7-.4.8.4.8.4l-2.1 1.2c-.6.3-1 1.2-1 2v3.4c0 .8.5 1.1 1 .8l.6-.4.6.5-1.3.7c-.9.5-1.7 0-1.7-1.3v-3.4c.2-1.2 1-2.6 1.9-3.2z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 710.1L352 708.6 351.6 708 349.3 709.2z"
                        />
                        <path
                          className="st24"
                          d="M352.1 708.4c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .2.2.4.2.5z"
                        />
                      </g>
                      <g>
                        <path
                          className="st24"
                          d="M349.3 713.7L352 712.3 351.6 711.6 349.3 712.9z"
                        />
                        <path
                          className="st24"
                          d="M352.1 712.1c0 .2-.1.3-.2.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.2c0 .1.2.3.2.5z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      className="st23"
                      d="M339.5 659L350 651.6 350.8 664.3 340.3 671.7z"
                    />
                    <path
                      className="st22"
                      d="M338.9 658.7L349.4 651.4 350.2 664 339.7 671.4z"
                    />
                    <path
                      className="st23"
                      d="M350.2 664L350.8 664.3 350 651.6 349.4 651.4z"
                    />
                    <path
                      className="st24"
                      d="M339.7 671.4L340.3 671.7 350.8 664.3 350.2 664z"
                    />
                    <path
                      className="st21"
                      d="M341.4 667.8L341.5 669.1 237.9 619.3 237.8 618z"
                    />
                    <path
                      className="st20"
                      d="M340.5 669.9L341.5 669.1 237.4 619.1 236.3 619.8z"
                    />
                    <g>
                      <path
                        className="st21"
                        d="M347.7 654.8L348.8 654.1 244.7 604.1 243.7 604.8z"
                      />
                      <path
                        className="st20"
                        d="M348.7 652.8L348.8 654.1 244.7 604.1 244.6 602.8z"
                      />
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M332.4 647.3l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                      />
                      <path
                        className="st21"
                        d="M332.5 647.2c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.1-.2-.2-.4-.1zM327.6 644.8c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.1-.1-.3-.3-.3z"
                      />
                      <path
                        className="st21"
                        d="M337.8 649.8c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.2-.1-.4-.3-.3z"
                      />
                      <g>
                        <path
                          className="st21"
                          d="M342.6 652.2l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                        />
                        <path
                          className="st21"
                          d="M342.7 652.1c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.1-.2-.2-.4-.1z"
                        />
                        <path
                          className="st21"
                          d="M348.9 662.8l-.7-8.3c-.3 0-.6-.1-.6.1l.6 8 .7.2z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M312 637.5l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                        />
                        <path
                          className="st21"
                          d="M312.1 637.4c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.2-.3-.2-.4-.1zM307.2 635c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.1-.1-.3-.3-.3z"
                        />
                        <path
                          className="st21"
                          d="M317.4 639.9c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.1-.1-.3-.3-.3z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M322.2 642.4l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                          />
                          <path
                            className="st21"
                            d="M322.3 642.3c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.1-.2-.2-.4-.1z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M291.6 627.7l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                        />
                        <path
                          className="st21"
                          d="M291.7 627.6c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.2-.3-.2-.4-.1zM286.8 625.2c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.2-.1-.3-.3-.3z"
                        />
                        <path
                          className="st21"
                          d="M297 630.1c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.1-.1-.3-.3-.3z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M301.8 632.6l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                          />
                          <path
                            className="st21"
                            d="M301.9 632.5c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.2-.3-.2-.4-.1z"
                          />
                        </g>
                        <g>
                          <path
                            className="st21"
                            d="M271.2 617.8l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.1-.2-.1-.3 0z"
                          />
                          <path
                            className="st21"
                            d="M271.3 617.8c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.2-.3-.2-.4-.1zM266.4 615.4c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.2-.1-.3-.3-.3z"
                          />
                          <path
                            className="st21"
                            d="M276.6 620.3c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.2-.1-.3-.3-.3z"
                          />
                          <g>
                            <path
                              className="st21"
                              d="M281.4 622.7l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.1-.2-.1-.3 0z"
                            />
                            <path
                              className="st21"
                              d="M281.5 622.7c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.2-.3-.2-.4-.1z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M325.8 660.9l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                      />
                      <path
                        className="st21"
                        d="M325.8 661.1c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1 0-.2-.1-.3-.2zM321 658.7c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2.1-.3 0-.4-.1z"
                      />
                      <path
                        className="st21"
                        d="M331.2 663.6c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2.1-.3 0-.4-.1z"
                      />
                      <g>
                        <path
                          className="st21"
                          d="M336 665.8l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                        />
                        <path
                          className="st21"
                          d="M336 666c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1 0-.2-.1-.3-.2z"
                        />
                        <path
                          className="st21"
                          d="M348.3 664.1l-6.9 4.7c-.2-.2-.4-.4-.3-.5l6.6-4.5.6.3z"
                        />
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M305.4 651.1l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                        />
                        <path
                          className="st21"
                          d="M305.4 651.2c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1.1-.2 0-.3-.2zM300.6 648.9c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2.1-.3 0-.4-.1z"
                        />
                        <path
                          className="st21"
                          d="M310.8 653.8c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2.1-.3 0-.4-.1z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M315.6 656l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                          />
                          <path
                            className="st21"
                            d="M315.6 656.1c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1.1-.2 0-.3-.2z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          className="st21"
                          d="M285 641.3l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                        />
                        <path
                          className="st21"
                          d="M285 641.4c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1.1-.2 0-.3-.2zM280.2 639.1c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2 0-.4 0-.4-.1z"
                        />
                        <path
                          className="st21"
                          d="M290.4 644c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2 0-.3 0-.4-.1z"
                        />
                        <g>
                          <path
                            className="st21"
                            d="M295.2 646.2l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                          />
                          <path
                            className="st21"
                            d="M295.2 646.3c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1.1-.2 0-.3-.2z"
                          />
                        </g>
                        <g>
                          <path
                            className="st21"
                            d="M264.6 631.5l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                          />
                          <path
                            className="st21"
                            d="M264.6 631.6c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1.1-.2 0-.3-.2zM259.8 629.3c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2 0-.4 0-.4-.1z"
                          />
                          <path
                            className="st21"
                            d="M270 634.2c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.2 0-.4 0-.4-.1zM261 613l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                          />
                          <path
                            className="st21"
                            d="M261.1 612.9c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c0-.2-.2-.2-.4-.1zM256.2 610.5c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.1-.1-.3-.3-.3z"
                          />
                          <path
                            className="st21"
                            d="M266.4 615.4c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.1-.1-.3-.3-.3z"
                          />
                          <g>
                            <path
                              className="st21"
                              d="M254.5 626.6l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.2-.1-.3-.2-.2-.4z"
                            />
                            <path
                              className="st21"
                              d="M254.5 626.7c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.2.1-.3 0-.3-.2zM249.6 624.4c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.1.1-.3 0-.4-.1z"
                            />
                            <path
                              className="st21"
                              d="M259.8 629.3c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.1.1-.3 0-.4-.1z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M250.8 608.1l-4.1 5.7.5.3 4-5.6c.1-.1 0-.3-.1-.4 0-.2-.2-.2-.3 0z"
                            />
                            <path
                              className="st21"
                              d="M250.9 608c-.1.1-.2.3-.1.4l5.2 9.8.9.4-5.6-10.5c-.1-.2-.2-.2-.4-.1zM246 605.6c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.2-.1-.3-.3-.3z"
                            />
                            <path
                              className="st21"
                              d="M256.2 610.5c-.2 0-.3.2-.3.3l.6 7.6.6.3-.6-7.9c0-.1-.1-.3-.3-.3z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M244.3 621.7l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.2-.1-.3-.2-.2-.4z"
                            />
                            <path
                              className="st21"
                              d="M244.3 621.8c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.2.1-.3 0-.3-.2zM239.4 619.5c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.1 0-.3 0-.4-.1z"
                            />
                            <path
                              className="st21"
                              d="M249.6 624.4c-.1-.1-.1-.3.1-.4l6.3-4.3.6.3-6.6 4.5c-.1.1-.3 0-.4-.1z"
                            />
                          </g>
                          <g>
                            <path
                              className="st21"
                              d="M274.8 636.4l1.9-6.7.5.3-1.9 6.6c-.1.1-.2.2-.3.2-.1-.1-.2-.2-.2-.4z"
                            />
                            <path
                              className="st21"
                              d="M274.8 636.5c0-.2.1-.3.2-.3l10.9-2.1.9.4-11.7 2.2c-.1.1-.2 0-.3-.2z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        className="st21"
                        d="M349.4 662.4L349.5 663.7 244.9 613.4 244.8 612.1z"
                      />
                      <path
                        className="st21"
                        d="M348.4 663.2L348.5 664.5 267.2 625.4 267.1 624.1z"
                      />
                      <path
                        className="st20"
                        d="M348.5 664.5L349.5 663.7 244.9 613.4 243.8 614.2z"
                      />
                    </g>
                    <g>
                      <path
                        className="st22"
                        d="M234.1 608.4L244.7 601 245.5 613.7 235 621.1z"
                      />
                      <path
                        className="st23"
                        d="M245.5 613.7L246.1 614 245.3 601.3 244.7 601z"
                      />
                      <path
                        className="st24"
                        d="M235 621.1L235.6 621.3 246.1 614 245.5 613.7z"
                      />
                    </g>
                    <g>
                      <path
                        className="st22"
                        d="M339.1 658L349.6 650.6 350.5 663.3 339.9 670.6z"
                      />
                      <path
                        className="st23"
                        d="M350.5 663.3L351.1 663.6 350.2 650.9 349.6 650.6z"
                      />
                      <path
                        className="st24"
                        d="M339.9 670.6L340.5 670.9 351.1 663.6 350.5 663.3z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <path
                    fill="#3c647c"
                    d="M330 744.5L330 665.8 243.1 626.1 243.1 700.6z"
                  />
                  <path
                    fill="#8e8e8e"
                    d="M330 744.5L333.2 742.4 333.2 663.6 330 665.8z"
                  />
                  <path fill="#ccc" d="M333.2 663.6L246.3 624 243.1 626.1 330 665.8z" />
                </g>
                <text transform="translate(387.346 635.47)">
                  <tspan x={0} y={0} className="st20 st31 st32">
                    {"BLACK"}
                  </tspan>
                  <tspan x={22.2} y={33.2} className="st20 st31 st32">
                    {"BOX"}
                  </tspan>
                </text>
                <g>
                  <path id='mapas2'
                    className="opciones"
                    d="M737.5 478.9c-66.6-38.5-148.7-38.5-215.4 0l-115.5 65.4L639 660l98.5-55.8c48.2-27.8 48.2-97.5 0-125.3z"
                  />
                </g>
                <text transform="translate(551.004 537.353)">
                  <tspan x={0} y={0} className="st20 st31 st32">
                    {"GOLDEN"}
                  </tspan>
                  <tspan x={34} y={33.2} className="st20 st31 st32">
                    {"BOX"}
                  </tspan>
                </text>
              </svg>

            
          </div>


        </div>

      </div >
      <div client="1" hall="2" session="0" id="rsr" className="hasSVG d-none">

        <ExampleDataTable />
      </div>
    </>
  )

}

export default Viewssvg